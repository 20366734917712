<template>
  <b-row style="height: 100%; margin: 0rem">
    <b-card style="height: 100%; width: 100%; padding-bottom: 3rem">
      <apexchart
        height="350"
        type="donut"
        :options="options"
        :series="series"
        :key="cont"
      />
    </b-card>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BFormRadio, BOverlay } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import store from "@/store/index";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { numeric } from "vee-validate/dist/rules";
import api from "@/service/api";

export default {
  props: ["request_device", "request_variable", "request_configs"],

  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    StatisticCardHorizontal,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      possible_datas: [],
      devices_datas: [],
      cont: 0,
      device: [],
      variable: [],
      options: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: "2rem",
                  fontFamily: "Montserrat",
                },
                value: {
                  fontSize: "1rem",
                  fontFamily: "Montserrat",
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`;
                  },
                },
                total: {
                  show: true,
                  fontSize: "1rem",
                },
              },
            },
          },          
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
        },
        title: {
          text: 'undefined',
          align: "center",
        },
        responsive: [
          {
            breakpoint: undefined,
            options: {},
          },
        ],
        labels: [],

        xaxis: {
          labels: {
            formatter: function (value) {
              return new Date(value).toLocaleString();
            },
          },
        },
      },

      series: [],
    };
  },
  async created() {    
    this.device = await api.get_devices();
    let variables = await api.get_variables();
    let selected_variable = this.request_variable.split(',');
    let selected_device = this.request_device.split('&id=');
    for(let i = 0; i < selected_variable.length; i++){
      for(let j = 1; j < selected_device.length; j++){
        let data = await api.get_data('&deviceId=' + this.request_device.split('&id=')[j], '&variableId=' + this.request_variable.split(',')[i]);      
        this.series.push(Math.abs(data[0].value));
        this.options.labels.push(this.device.find(e => e.id == this.request_device.split('&id=')[j]).name + ' - ' + variables.find(e => e.id == selected_variable[i]).name);      
      }
    }    
    this.options.title.text = this.request_configs;
    console.log("optrions é : ", this.options);
    console.log('this.request_configs: ', this.request_configs);
  },
  destroyed() {
    // this.stop_loop = true;
  },
  methods: {
    async request_data() {
      if (this.stop_loop == true) {
        clearInterval(this.on_loop);
      }
      this.loop = false;
      let request_url =
        "https://tekie.cloud:3031/datas?$limit=1&$skip=0&deviceId=" +
        this.request_device.substring(4) +
        "&variableId=" +
        this.request_variable;
      await axios
        .get(request_url, {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.get_data = { value: 0 };
          if (response.data.data.length != 0) {
            this.get_data = response.data.data[0];
          }
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });
    },
  },
};
</script>

<style>
</style>