<template>
  <b-card
    style="height: 100%; width: 100%; padding: 1rem 1rem 1rem 1rem;align-items: center;"
  >
    <div style="margin-bottom: 2rem; text-align: center;">
      <h2 class="title">{{ get_data.value }} {{ variable.unit }}</h2>
      <b-badge variant="light-primary">
        {{ variable.name }}
      </b-badge>
    </div>
    <div>
      <div class="demo-inline-spacing" v-if="ready">
        <b-avatar
          variant="light-primary"
          style="margin-right: 0.5rem; margin-top: 0rem"
        >
          <feather-icon :icon="possible_icons[device.icon].icon" size="20" />
        </b-avatar>
        <div style="margin-top: 0rem">
          <h5 class="mb-0">
            {{ device.name }}
          </h5>
          <b-badge variant="light-dark">
            {{ new Date(get_data.valueTime * 1000).toLocaleString() }}
          </b-badge>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BAvatar, BBadge, VBPopover, BPopover} from "bootstrap-vue";
import api from "../../service/api.js";
import Ripple from 'vue-ripple-directive'

export default {
  props: ["request_device", "request_variable"],

  components: {
    BBadge,
    BPopover,
    BAvatar,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      ready: false,
      on_loop: "",
      stop_loop: false,
      loop: true,
      patch_widget: "",
      ready: false,
      widget_title: "",
      get_data: [],
      variable: [],
      device: [],
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
    };
  },
  async created() {
    this.device = (await api.find_devices("id=" + this.request_device))[0];
    this.variable = (
      await api.find_variables("?id=" + this.request_variable)
    )[0];
    try {
      this.get_data = (
        await api.get_data(
          "&deviceId=" + this.request_device.replace("&id=", ""),
          "&variableId=" + this.request_variable
        )
      )[0];
    } catch {}
    if( this.get_data == undefined){
      this.get_data = {
        value: "Não há dados",
        valueTime: 0,
      };
      this.variable.unit = '' 
    } else {
      this.loop = true;
      this.on_loop = setInterval(this.request_data, 10000);
    }
    this.ready = true;
  },
  destroyed() {
    this.stop_loop = true;
  },
  methods: {
    async request_data() {
      if (this.stop_loop == true) {
        clearInterval(this.on_loop);
      }
      this.loop = false;
      try {
        this.get_data = (
          await api.get_data(
            "&deviceId=" + this.request_device.replace("&id=", ""),
            "&variableId=" + this.request_variable
          )
        )[0];
      } catch {
        this.get_data = {
          name: "Não há dados",
          valueTime: 0,
        };
        this.variable = {
          name: "",
        };
      }
    },
  },
};
</script>

<style>
.title {
  font-weight: bolder;
}
</style>
