<template :key="'template_' + cont">
  <!--
        Informações sobre página de Dashboard

        possible_dash_content:
            0 - Tipo de widget ( mapa, barras, etc);
            1 - Dispositivos
            2 - Variáveis
            3 - Pos X
            4 - Pos Y
            5 - Comprimento
            6 - Largura 
            7 - Outras configurações ( depende do widget );

        Donut Widget: 
            7 - Title
        
        dash_pos_content:
            "x": int,"y": int,"w": int,"h": int,"i":" int", static: bool
    -->
  <div class="dashboard-page">
    <b-row>
      <b-col md="10" v-if="is_static">
        <b-button to="/home" variant="flat-dark">
          <feather-icon size="22" icon="ArrowLeftIcon" color />
        </b-button>
        <b-dropdown
          size="lg"
          :text="selected_dashboard.name"
          variant="flat-dark"
        >
          <b-dropdown-item
            v-for="(item, index) in possible_dashboards"
            :key="'option_' + index"
            @click="change_dashboard(item)"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col md="2" v-if="!is_static">
        <b-button v-b-modal.modal-add_dash variant="outline-success">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Nova dashboard</span>
        </b-button>
      </b-col>

      <b-col md="8" v-if="!is_static">
        <b-row style="text-align: center">
          <p
            style="
              height: 1.8rem;
              margin: 0px;
              align-self: center;
              margin-left: 0.5rem;
            "
            v-if="!is_static"
          >
            Dashboard:
          </p>

          <b-col md="4">
            <v-select
              id="dashboard_atual"
              v-model="vselect_dashboard"
              label="name"
              :options="possible_dashboards"
            />
          </b-col>

          <b-button
            @click="
              selected_dashboard_group = get_group.find(
                (data) => data.id == selected_dashboard.groupId
              )
            "
            v-b-modal.modal-widget
            variant="outline-success"
            style="margin-right: 1.5rem"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Novo widget</span>
          </b-button>

          <b-button
            @click="
              edit_dash_name = selected_dashboard.name;
              edit_dash_description = selected_dashboard.description;
              edit_dash_group = get_group.find(
                (data) => data.id == selected_dashboard.groupId
              );
            "
            v-b-modal.modal-edit_dash
            variant="outline-success"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span class="align-middle">Editar</span>
          </b-button>

          <b-button
            @click="sync_positions()"
            variant="outline-danger"
            style="margin-left: 1.5rem"
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            Salvar
          </b-button>
          <b-button
            v-if="!overlay"
            @click="overlay = true"
            variant="success"
            class="btn-icon"
            style="margin-left: 1rem"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>

          <div style="flex: auto; text-align: right">
            <b-modal
              ref="add_dash-modal"
              title="Criar dashboard"
              id="modal-add_dash"
              centered
              hide-footer
              no-close-on-backdrop
            >
              <b-form-group label="Grupo" label-for="group">
                <v-select
                  id="group"
                  label="name"
                  v-model="selected_group"
                  :options="$store.state.group_exist"
                />
              </b-form-group>
              <b-form-group label="Nome" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Nome da dashboard"
                  v-model="dash_name"
                />
              </b-form-group>
              <b-form-group label="Descrição" label-for="description">
                <b-form-input
                  id="description"
                  placeholder="Descrição da dashboard ( Opcional )"
                  v-model="dash_description"
                />
              </b-form-group>
              <div
                style="margin-top: 2rem; text-align: end"
                @click="hideModal('add_dash-modal')"
              >
                <b-button style="margin-right: 1.5rem" variant="danger">
                  Cancelar
                </b-button>

                <b-button @click="create_dash()" variant="success">
                  Criar
                </b-button>
              </div>
            </b-modal>
          </div>

          <b-modal
            ref="modal-edit_dash"
            title="Editar dashboard"
            id="modal-edit_dash"
            centered
            hide-footer
            no-close-on-backdrop
          >
            <b-form-group label="Grupo" label-for="group">
              <v-select
                id="group"
                label="name"
                v-model="edit_dash_group"
                :options="$store.state.group_exist"
                disabled
              />
            </b-form-group>
            <b-form-group label="Nome" label-for="name">
              <b-form-input
                id="name"
                placeholder="Nome da dashboard"
                v-model="edit_dash_name"
              />
            </b-form-group>
            <b-form-group label="Descrição" label-for="description">
              <b-form-input
                id="description"
                placeholder="Descrição da dashboard ( Opcional )"
                v-model="edit_dash_description"
              />
            </b-form-group>
            <div
              style="margin-top: 2rem; text-align: end"
              @click="hideModal('modal-edit_dash')"
            >
              <b-button style="margin-right: 1.5rem" variant="danger">
                Cancelar
              </b-button>

              <b-button @click="edit_dash()" variant="success">
                Editar
              </b-button>
            </div>
          </b-modal>
        </b-row>
      </b-col>

      <b-col md="2" style="flex: auto; text-align: right; align-self: center;">
        <b-form-checkbox
          v-model="is_static"
          class="custom-control-dark"
          name="check-button"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="EyeIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="EditIcon" />
          </span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <grid-layout
      :layout.sync="dash_pos_content"
      :key="'Layout_'"
      v-if="selected_dashboard.content != '' && dash_pos_content != ''"
      responsive
      :col-num="16"
      :row-height="16"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :vertical-compact="false"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="(item, index) in dash_pos_content"
        :key="'dash_Id + ' + index + cont"
        :static="is_static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        
      >
        <b-overlay
          style="height: 100%"
          :show="!is_static && overlay"
          rounded="sm"
          opacity="0.7"
        >
          <div style="height: 100%">
            <BoxCard
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'box'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :key="'widget_Id' + index"
            />
            
            <BasicCard
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'bars'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :key="'widget_Id' + index"
            />

            <Mapbox
            style="padding: 0rem 1rem 0rem 0rem"
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'map'
              "
              :request="widgets_devices[item.i]"
              default_mode="true"
              solo_mode="false"
            />

            <SimpleGraph
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'simple_line'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :key="'widget_Id' + cont"
            />

            <LinesGraph
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'lines'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :key="'widget_Id' + cont"
            />

            <Table
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'table'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :key="'widget_Id' + cont"
            />

            <DonutGraph
              v-if="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 1)[0] == 'donut'
              "
              :request_device="widgets_devices[item.i]"
              :request_variable="
                selected_dashboard.content
                  .split('*', 10)
                  [item.i].split(';', 10)[2]
              "
              :request_configs="other_configs"
              :key="'widget_Id' + cont"
            />
          </div>

          <template #overlay>
            <b-row>
              <b-button
                @click="select_widget(item)"
                v-b-modal.edit-widget
                variant="secondary"
                class="btn-icon"
                style="margin-right: 1rem"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                @click="overlay = false"
                variant="secondary"
                class="btn-icon"
                style="margin-right: 1rem"
              >
                <feather-icon icon="CropIcon" />
              </b-button>
              <b-button
                @click="delete_widget(item)"
                variant="secondary"
                class="btn-icon"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-row>
          </template>
        </b-overlay>
      </grid-item>
    </grid-layout>
    <b-modal
      ref="widget-modal"
      title="Criar widget"
      id="modal-widget"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          md="4"
          v-for="item in possible_icons"
          :key="'card_' + item.id"
          @click="change_icon(item.id)"
          style="flex: auto"
        >
          <b-card
            style="
              text-align: center;
              margin-top: 1rem;
              margin-right: 1rem;
              max-width: 10rem;
              cursor: pointer;              
            "
          >
            <div class="icon-wrapper">
              <b-button :variant="item.selected" class="btn-icon">
                <feather-icon :icon="item.icon" />
              </b-button>
            </div>
            <p class="card-text icon-name text-truncate mb-0 mt-1">
              {{ item.text }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-form-group label="Dispositivos" label-for="devices">
        <v-select
          multiple
          v-model="selected_devices"
          id="devices"
          label="name"
          :options="possible_devices"
          :selectable="
            () =>
              selected_devices.length <
              possible_icons[this.selected_icon].devices_limit
          "
        />
      </b-form-group>
      <b-form-group label="Variáveis" label-for="variable">
        <v-select
          multiple
          v-model="possible_icons[selected_icon].selected_variable"
          id="variable"
          label="name"
          :options="get_variables"
          :selectable="
            () =>
              possible_icons[selected_icon].selected_variable.length <
              possible_icons[this.selected_icon].variables_limit
          "
        />
      </b-form-group>
      <b-form-group
        v-if="possible_icons[selected_icon].title"
        label="Título"
        label-for="title"
      >
        <b-form-input
          id="title"
          placeholder="Nome do widget"
          v-model="widget_title"
        />
      </b-form-group>
      <div
        style="margin-top: 2rem; text-align: end"
        @click="hideModal('widget-modal')"
      >
        <b-button style="margin-right: 1.5rem" variant="danger">
          Cancelar
        </b-button>
        <b-button @click="create_widget()" variant="success"> Criar </b-button>
      </div>
    </b-modal>

    <b-modal
      ref="edit-widget"
      title="Editar widget"
      id="edit-widget"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-form-group
        v-if="possible_icons[selected_icon].title"
        label="Título"
        label-for="title"
      >
        <b-form-input
          id="title"
          placeholder="Nome do widget"
          v-model="widget_title"
        />
      </b-form-group>
      <b-form-group label="Dispositivos" label-for="devices">
        <v-select
          multiple
          v-model="selected_widget_device"
          id="devices"
          label="name"
          :options="possible_devices"
        />
      </b-form-group>
      <b-form-group label="Variáveis" label-for="variable">
        <v-select
          multiple
          v-model="selected_widget_variables"
          id="variable"
          label="name"
          :options="get_variables"
        />
      </b-form-group>
      <div
        style="margin-top: 2rem; text-align: end"
        @click="hideModal('edit-widget')"
      >
        <b-button style="margin-right: 1.5rem" variant="outline-danger">
          Cancelar
        </b-button>
        <b-button @click="patch_widget()" variant="outline-success">
          Editar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormSelect,
  BFormRadio,
  VBToggle,
  BCollapse,
  BCard,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BAvatar,
  BCardGroup,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { GridLayout, GridItem } from "vue-grid-layout";
import BasicCard from "../layouts/components/BasicCard.vue";
import BoxCard from "../layouts/components/BoxCard.vue";
import Cookies from "js-cookie";
import store from "@/store/index";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Mapbox from "../layouts/components/MapDashboard.vue";
import SimpleGraph from "../layouts/components/simple_graph.vue";
import LinesGraph from "../layouts/components/lines_graph.vue";
import DonutGraph from "../layouts/components/donut_graph.vue";
import Table from "../layouts/components/table.vue";
import api from "@/service/api";

export default {
  components: {
    VBTooltip,
    BoxCard,
    DonutGraph,
    Table,
    LinesGraph,
    SimpleGraph,
    BOverlay,
    BDropdownDivider,
    BDropdownItem,
    BDropdown,
    BFormSelect,
    VBToggle,
    BCollapse,
    GridLayout,
    GridItem,
    BasicCard,
    BModal,
    VBModal,
    BButton,
    BCardGroup,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BCard,
    BBadge,
    vSelect,
    Mapbox,
    BFormCheckbox,
    BFormRadio,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }

    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }    
    
    let response = await api.get_dashboards();
    self.possible_dashboards = response;
    self.selected_dashboard = response[0];
    self.vselect_dashboard = self.selected_dashboard;
    self.dashboard_group = store.state.group_exist.find(
      (data) => data.id == self.selected_dashboard.groupId
    );

    this.initial_requests();
    this.format_content();
  },
  data() {
    return {
      widget_title: "",
      selected_dashboard: [],
      overlay: true,
      selected_dashboard_group: "",
      show: false,
      edit_dash_group: "",
      get_group: [],
      edit_dash_name: "",
      edit_dash_description: "",
      vselect_dashboard: "",
      selected_group: "",
      possible_dashboards: [],
      dash_name: "",
      dash_description: "",
      selected_widget: [],
      selected_widget_variables: [],
      selected_widget_device: [],
      is_static: true,
      widgets_devices: [],
      cont: 0,
      dash_pos_content: [],
      possible_dash_content: [],
      get_variables: [],
      selected_devices: [],
      possible_devices: [],
      dashboard_group: [],
      possible_icons: [
        {
          icon: "MapIcon",
          text: "Mapa",
          id: 0,
          selected: "flat-success",
          type: "map",
          coordinates: "0;0;5;18;",
          devices_limit: 10,
          variables_limit: 2,
          selected_variable: [
            {
              id: 7,
              name: "Localização",
            },
          ],
        },
        {
          icon: "CreditCardIcon",
          text: "SmallCard",
          id: 1,
          selected: "flat-darken-2",
          type: "bars",
          coordinates: "0;0;3;4;",
          devices_limit: 1,
          variables_limit: 1,
          selected_variable: [],
        },
        {
          icon: "TrendingUpIcon",
          text: "Gráfico de linha #1",
          id: 2,
          selected: "flat-darken-2",
          type: "simple_line",
          coordinates: "0;0;6;14;",
          devices_limit: 1,
          variables_limit: 1,
          selected_variable: [],
        },
        {
          icon: "ActivityIcon",
          text: "Gráfico de linha #2",
          id: 3,
          selected: "flat-darken-2",
          type: "lines",
          coordinates: "0;0;6;14;",
          devices_limit: 5,
          variables_limit: 1,
          selected_variable: [],
        },
        {
          icon: "CreditCardIcon",
          text: "Tabela",
          id: 4,
          selected: "flat-darken-2",
          type: "table",
          coordinates: "0;0;6;14;",
          devices_limit: 20,
          variables_limit: 10,
          selected_variable: [],
        },
        {
          icon: "PieChartIcon",
          text: "Donut",
          id: 5,
          selected: "flat-darken-2",
          type: "donut",
          coordinates: "0;0;6;14;",
          devices_limit: 2,
          variables_limit: 2,
          selected_variable: [],
          title: true,
        },
        {
          icon: "BoxIcon",
          text: "BoxCard",
          id: 6,
          selected: "flat-darken-2",
          type: "box",
          coordinates: "0;0;2;8;",
          devices_limit: 1,
          variables_limit: 1,
          selected_variable: [],
          title: false,
        },
      ],
      selected_icon: 0,
      draggable: true,
      resizable: true,
    };
  },
  methods: {
    async edit_dash() {
      var self = this;
      var data = {
        groupId: self.selected_dashboard.groupId,
        id: self.selected_dashboard.id,
        content: self.selected_dashboard.content,
        description: this.edit_dash_description,
        name: this.edit_dash_name,
      };
      // Falta API
      let patched_dashboard = await api.patch_dashboard(data);
      self.selected_dashboard = patched_dashboard.data[0];
      self.vselect_dashboard = patched_dashboard.data[0];

      this.initial_requests();
      this.format_content();
    },
    change_dashboard(value) {
      this.vselect_dashboard = value;
      self.selected_dashboard = value;
      this.cont += 1;
      this.initial_requests();
      this.format_content();
      this.cont += 1;
    },
    async create_dash() {
      let data = {
        name: this.dash_name,
        description: this.dash_description,
        content: "",
        groupId: this.selected_group.id,
      };
      await api.post_dashboard(data);

      this.initial_requests();
      this.cont += 1;
    },
    async delete_widget(value) {
      var self = this;
      let new_content_0 = "";
      let new_content_1 = "";
      let new_content = "";
      this.selected_widget = self.selected_dashboard.content.split("*", 10)[
        value.i
      ];
      if (self.selected_dashboard.content.split("*", 10).length <= 1) {
        new_content = "";
      } else {
        new_content_0 = self.selected_dashboard.content.replace(
          this.selected_widget + "*",
          ""
        );
        new_content_1 = new_content_0.replace("*" + this.selected_widget, "");
        new_content = new_content_1.replace(this.selected_widget, "");
      }

      var data = {
        groupId: this.dashboard_group.id,
        id: self.selected_dashboard.id,
        content: new_content,
      };

      await api.patch_dashboard(data);
      self.selected_dashboard.content = new_content;
      this.cont += 1;
      this.format_content();
      this.sync_positions();
    },
    async patch_widget() {
      var self = this;

      let id_devices = "";
      let id_variables = "";
      for (let i = 0; i < this.selected_widget_device.length; i++) {
        if (i != this.selected_widget_device.length - 1) {
          id_devices += this.selected_widget_device[i].id + ",";
        } else {
          id_devices += this.selected_widget_device[i].id + ";";
        }
      }

      for (let i = 0; i < this.selected_widget_variables.length; i++) {
        if (i != this.selected_widget_variables.length - 1) {
          id_variables += this.selected_widget_variables[i].id + ",";
        } else {
          id_variables += this.selected_widget_variables[i].id + ";";
        }
      }
      let widget_formatted = this.selected_widget.split(";");
      let edited_widget = widget_formatted[0] + ";" + id_devices + id_variables;
      for (let i = 3; i <= 6; i++) {
        // adicionando as posições ( de 3 a 6 )
        edited_widget += widget_formatted[i] + ";";
      }
      edited_widget += this.widget_title;
      let new_content = self.selected_dashboard.content.replace(
        this.selected_widget,
        edited_widget
      );

      var data = {
        groupId: this.dashboard_group.id,
        id: self.selected_dashboard.id,
        content: new_content,
      };

      let patched_dashboard = await api.patch_dashboard(data);
      self.selected_dashboard = patched_dashboard.data[0];
      this.cont += 1;
      this.sync_positions();
    },
    select_widget(value) {
      var self = this;
      self.selected_widget = self.selected_dashboard.content.split("*", 10)[
        value.i
      ];
      self.selected_widget_device = [];
      self.selected_widget_variables = [];
      for (
        let i = 0;
        i < self.selected_widget.split(";", 100)[1].split(",", 100).length;
        i++
      ) {
        self.selected_widget_device[i] = [];
        self.selected_widget_device[i] = self.possible_devices.find(
          (data) =>
            data.id == self.selected_widget.split(";", 10)[1].split(",", 100)[i]
        );
      }
      for (
        let i = 0;
        i < self.selected_widget.split(";", 100)[2].split(",", 100).length;
        i++
      ) {
        self.selected_widget_variables[i] = [];
        self.selected_widget_variables[i] = self.get_variables.find(
          (data) =>
            data.id == self.selected_widget.split(";", 10)[2].split(",", 10)[i]
        );
      }
      self.widget_title = "";
    },
    hideModal(value) {
      this.$refs[value].hide();
    },
    change_icon(value) {
      this.selected_devices = "";
      this.possible_icons[this.selected_icon].selected = "flat-darken-2";
      this.possible_icons[value].selected = "flat-success";
      this.selected_icon = value;
    },
    format_content() {
      var self = this;
      self.dash_pos_content = [];
      self.possible_dash_content = [];
      if (self.selected_dashboard.content != "") {
        for (
          let i = 0;
          i < self.selected_dashboard.content.split("*").length;
          i++
        ) {
          self.widgets_devices[i] =
            "&id=" +
            self.selected_dashboard.content
              .split("*")
              [i].split(";")[1]
              .replace(/,/gi, "&id=");
          self.possible_dash_content[i] = self.selected_dashboard.content
            .split("*", 10)
            [i].split(";");
          self.other_configs = self.selected_dashboard.content
            .split("*")
            [i].split(";")[7];
          self.dash_pos_content.push({
            x: parseInt(self.possible_dash_content[i][3]),
            y: parseInt(self.possible_dash_content[i][4]),
            w: parseInt(self.possible_dash_content[i][5]),
            h: parseInt(self.possible_dash_content[i][6]),
            i: parseInt(i),
          });
        }
      }
      self.cont += 1;
    },
    async initial_requests() {
      let self = this;

      let devices = await api.get_devices();
      self.possible_devices = devices.filter(
        (data) => data.groupId == self.selected_dashboard.groupId
      );
      self.get_group = await api.get_groups();
      self.get_variables = await api.get_variables();
    },
    async create_widget() {
      var self = this;
      var id_devices = "";
      var id_variables = "";
      var other_configs = this.widget_title;
      self.cont += 1;

      for (let i = 0; i < self.selected_devices.length; i++) {
        if (i != self.selected_devices.length - 1) {
          id_devices += self.selected_devices[i].id + ",";
        } else {
          id_devices += self.selected_devices[i].id + ";";
        }
      }
      for (
        let i = 0;
        i < self.possible_icons[self.selected_icon].selected_variable.length;
        i++
      ) {
        if (
          i !=
          self.possible_icons[self.selected_icon].selected_variable.length - 1
        ) {
          id_variables +=
            self.possible_icons[self.selected_icon].selected_variable[i].id +
            ",";
        } else {
          id_variables +=
            self.possible_icons[self.selected_icon].selected_variable[i].id +
            ";";
        }
      }

      if (self.selected_dashboard.content != "") {
        var data = {
          groupId: self.dashboard_group.id,
          id: self.selected_dashboard.id,
          content:
            self.selected_dashboard.content +
            "*" +
            self.possible_icons[self.selected_icon].type +
            ";" +
            id_devices +
            id_variables +
            self.possible_icons[self.selected_icon].coordinates +
            other_configs,
        };
      } else {
        var data = {
          groupId: self.dashboard_group.id,
          id: self.selected_dashboard.id,
          content:
            self.possible_icons[self.selected_icon].type +
            ";" +
            id_devices +
            id_variables +
            self.possible_icons[self.selected_icon].coordinates +
            other_configs,
        };
      }

      let patched_dashboard = await api.patch_dashboard(data);
      self.selected_dashboard = patched_dashboard.data[0];
      self.possible_dash_content = [];
      for (
        let i = 0;
        i < self.selected_dashboard.content.split("*").length;
        i++
      ) {
        self.possible_dash_content[i] = self.selected_dashboard.content
          .split("*")
          [i].split(";");
      }

      self.initial_requests();
      self.format_content();
    },
    async sync_positions() {
      var self = this;
      let new_content = [];
      let content = "";
      for (let i = 0; i < this.dash_pos_content.length; i++) {
        new_content[i] = self.selected_dashboard.content
          .split("*", 10)
          [i].split(";", 7);
        new_content[i][3] = this.dash_pos_content[i].x + "";
        new_content[i][4] = this.dash_pos_content[i].y + "";
        new_content[i][5] = this.dash_pos_content[i].w + "";
        new_content[i][6] = this.dash_pos_content[i].h + "";
      }
      let data = {
        groupId: this.dashboard_group.id,
        id: self.selected_dashboard.id,
        content: "",
      };

      for (let i = 0; i < new_content.length; i++) {
        for (let j = 0; j < 7; j++) {
          content += new_content[i][j] + ";";
        }
        content += "*";
      }
      data.content = content.slice(0, -1);

      let patched_dashboard = await api.patch_dashboard(data);
      self.selected_dashboard = patched_dashboard.data[0];
      for (
        let i = 0;
        i < self.selected_dashboard.content.split("*", 10).length;
        i++
      ) {
        self.possible_dash_content[i] = self.selected_dashboard.content
          .split("*", 10)
          [i].split(";", 7);
      }

      this.cont += 1;
      this.is_static = true;
      this.initial_requests();
      this.format_content();
      this.cont += 1;
    },
  },
  watch: {
    vselect_dashboard: function() {
      var self = this;
      self.selected_dashboard = this.vselect_dashboard;
      this.cont += 1;
      this.initial_requests();
      this.format_content();
      this.cont += 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.dashboard-page {
  padding: 1rem;
}
.vue-grid-layout {
  background: #00000000;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #00000000;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 1px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-position: bottom right;
  padding: 0 2px 2px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
</style>
