<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <statistic-card-with-area-chart
            v-if="ready"
            :icon="possible_icons[device.icon].icon"
            :statistic="variable.name"
            :statistic-title="device.name"
            :chart-data="subscribersGained.series"
            :key="cont + subscribersGained.series[0].data[0].value"
        />
      </b-card>
    </b-col>
  </b-row>
  
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard } from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import axios from "axios";
import store from '@/store/index'

export default {
  components: {
    StatisticCardWithAreaChart,
    BRow,
    BCol,
    BCard,
  },
  props: ['request_device', 'request_variable'],
  data() {
    return {
      destroy_it: false,
      on_loop: '',
      cont: 0,
      get_data: [],
      ready: false,
      subscribersGained: {
        series: [{
          data: [0,0]
        }]      
      },
      possible_icons: [
       {
         icon: 'BoxIcon',
         text: 'Cubo',
         id: 0,
         selected: 'flat-success'
       },
       {
         icon: 'BatteryIcon',
         text: 'Bateria',
         id: 1,
         selected: 'flat-darken-2'
       },
       {
         icon: 'DropletIcon',
         text: 'Humidade',
         id: 2,
         selected: 'flat-darken-2'
       },
       {
         icon: 'ThermometerIcon',
         text: 'Temperatura',
         id: 3,
         selected: 'flat-darken-2'
       },
       {
         icon: 'NavigationIcon',
         text: 'Seta',
         id: 4,
         selected: 'flat-darken-2'
       },
       {
         icon: 'Navigation2Icon',
         text: 'Seta#2',
         id: 5,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MousePointerIcon',
         text: 'Mouse',
         id: 6,
         selected: 'flat-darken-2'
       },
       {
         icon: 'TruckIcon',
         text: 'Caminhão',
         id: 7,
         selected: 'flat-darken-2'
       },
       {
         icon: 'UserIcon',
         text: 'Usuário',
         id: 8,
         selected: 'flat-darken-2'
       },
       {
         icon: 'WatchIcon',
         text: 'Relógio',
         id: 9,
         selected: 'flat-darken-2'
       },
       {
         icon: 'EyeIcon',
         text: 'Olho',
         id: 10,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MusicIcon',
         text: 'Música',
         id: 11,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HeartIcon',
         text: 'Coração',
         id: 12,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HomeIcon',
         text: 'Casa',
         id: 13,
         selected: 'flat-darken-2'
       },
    
     ],
    }
  },
  async created() {
    await axios
        .get("https://tekie.cloud:3031/devices?" + this.request_device , {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.device = response.data.data[0];
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });

    await axios
      .get("https://tekie.cloud:3031/variables?id=" + this.request_variable , {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {
        this.variable = response.data.data[0]
      })
      .catch((e) => {
        //"Erro ao pegar variables: ", e);
      });
    this.on_loop = setInterval(this.request_data, 3000); 
    this.request_data()
    this.ready = true;
  },
  destroyed(){
    this.destroy_it = true;
  },
  methods: {
    async request_data(){
      if(this.destroy_it){
        clearInterval(this.on_loop);
      }
      let prev_data = [];
      let request_url = "https://tekie.cloud:3031/datas?$limit=10&deviceId=" + this.request_device.substring(4) + "&variableId=" + this.request_variable;
      await axios
      .get(request_url, {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {
        
        response.data.data.reverse().forEach((element, index) => {
          prev_data[index] = element.value
        });

        if(JSON.stringify(prev_data) != JSON.stringify(this.subscribersGained.series[0].data) || this.cont == 0){
          if(prev_data.length >= 1){
            this.subscribersGained.series[0].data = prev_data;
            this.cont += 1
          }
        }
      })
      .catch((e) => {
        //"Erro ao pegar devices: ", e);
      });
            
    }
  }
}
</script>
