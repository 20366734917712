<template>
    <b-row style="height:100%; margin: 0rem" >
      <b-card style="height:100%; width: 100%; padding-bottom: 2rem">
        <apexchart 
        width="100%" 
        height="100%" 
        type="line" 
        :options="options" 
        :series="series" 
        :key="cont"
        />
      </b-card>
    </b-row>
</template>

<script>
import { BCard, BRow, BCol, BFormRadio } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import store from '@/store/index'
import axios from "axios";
import VueApexCharts from 'vue-apexcharts'
import { numeric } from 'vee-validate/dist/rules';

export default {
    props: ['request_device', 'request_variable'],
 
    components: {
        BCard,
        BRow,
        BCol,
        BFormRadio,
        StatisticCardHorizontal,
        apexchart: VueApexCharts,
    },
    data() {
    return {
      init_timestamp: 0,
      end_timestamp: 0,
      on_loop: false,
      devices_datas: [],
      cont: 0,
      device: [],
      variable: [],
      options: {      
        title: {
          text: 'Vazio',
          align: 'left'
        },
        responsive: [
        {
          breakpoint: undefined,
          options: {}
        }
      ],
      
      xaxis:{
        labels: {
          show: false,
          formatter: function (value) {
            return new Date(value * 1000 ).toLocaleString();
          }
        },
      }
    },
     
      series: []
    };
  },
  async created() {
    this.sync_date();
    await axios
        .get("https://tekie.cloud:3031/devices?id=" + this.request_device , {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.device = response.data.data
          this.device.forEach(element => {
            this.series.push({
              name: element.name,
              data: []
            })
          });
          
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });

    await axios
      .get("https://tekie.cloud:3031/variables?id=" + this.request_variable , {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {
        this.variable = response.data.data[0]
        this.options.title.text = this.variable.name
        this.cont += 1;
      })
      .catch((e) => {
        //"Erro ao pegar variables: ", e);
      });

      let request_url = "https://tekie.cloud:3031/datas?$limit=1000&$skip=0" + this.request_device.replace(/&id=/gi, '&deviceId=') + "&variableId=" + this.request_variable;
      await axios
      .get(request_url, {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {

        var new_data = response.data.data.filter(filter_data => {
          if(this.end_timestamp != 0){
            if(this.init_timestamp != 0){
              return (filter_data.valueTime <= this.end_timestamp && filter_data.valueTime >= this.init_timestamp)
            } else{
              return (filter_data.valueTime <= this.end_timestamp)
            }
          } else{
            if( this.init_timestamp != 0){
              return (filter_data.valueTime >= this.init_timestamp)
            }
          }
          return filter_data
        });

        this.request_device.split("&id=", 30).forEach(element => {
          if(element != ""){
            this.devices_datas.push(
            {
              data: new_data.filter(filter_data => filter_data.deviceId == element)
            }
          )}
        })
        this.devices_datas.forEach((element, index) => {
          for(let i = 0; i < element.data.length; i++){
            this.series[index].data[i] = [(element.data[i].valueTime), element.data[i].value]
          }
        })
        this.cont += 1;               
      })
      .catch((e) => {
        //"Erro ao pegar datas: ", e);
      });
      

      // this.loop = true;
      // this.on_loop = setInterval(this.request_data, 3000); 
  },
  destroyed(){
    // this.stop_loop = true;
  },
  methods: {
    async request_data(){
      if(this.stop_loop == true){
        clearInterval(this.on_loop)
      }
      this.loop = false;
      let request_url = "https://tekie.cloud:3031/datas?$limit=1&$skip=0&deviceId=" + this.request_device.substring(4) + "&variableId=" + this.request_variable;
      await axios
        .get(request_url, {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.get_data = 
            {value: 0}
          if(response.data.data.length != 0){
            this.get_data = response.data.data[0]
          }
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });
    },
    sync_date(){      
      var start = new Date();
      start.setHours(0,1,0,0);
      var end = new Date();
      end.setUTCHours(21,59,59);
      
      this.init_timestamp = start/1000
      this.end_timestamp = end/1000
    }

  },
}
</script>

<style>

</style>
