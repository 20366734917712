<template>
  <b-overlay
    style="width: 100%; height: 100%"
    opacity="0.5"
    :show="!ready"
    spinner-variant="success"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row style="height: 100%; margin-left: 0px">
      <!-- Card da dashboard e condições: Selecionar dispositivo -->
      
      <b-col
        md="3"
        style="background: #ffffff; padding-top: 1rem; height: 100%"
      >
        <b-overlay
          style="width: 100%; height: 100%"
          opacity="0.5"
          :show="device_overlay"
          spinner-variant="success"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <div v-for="(item, index) in filtered_devices" :key="'device' + index">
            <a
              class="demo-inline-spacing devices"
              style="padding: 0.5rem"
              @click="onChangeDevice(item.id)"
            >
              <b-avatar
                variant="light-dark"
                style="margin-right: 0.5rem; margin-top: 0rem"
                :key="'avata_cont:' + cont"
              >
                <feather-icon :icon="possible_icons[item.icon].icon" size="20" />
              </b-avatar>
              <div style="margin-top: 0rem">
                <h5 class="mb-0">
                  {{ item.name }}
                </h5>
                <small class="text-muted">
                  #
                  {{ item.id }}
                </small>
              </div>
            </a>
            <div
              style="margin-left: 1rem; margin-right: 1rem; margin-top: 1rem font-family: 'Montserrat', sans-serif; mso-line-height-rule: exactly; height: 1px; background-color: #eceff1; line-height: 1px;"
            >
              &zwnj;
            </div>
          </div>
        </b-overlay>
      </b-col>

      <b-col
        md="9"
        style="
        height: 100%;
        width: 100%;
        margin-bottom: 0rem;
        padding-left: 0px;
        padding-right: 0rem;
        background: #d3d3d3;
      "
      >
        <div id="map-container" style="width: 100%; height: 100%">
          <MglMap
            v-if="ready"
            container="map-container"
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="center"
            :zoom="zoom"
            @load="onMapLoaded"
          >
            <!-- MglMarker para dashboard -->
            <MglMarker
              v-for="(item, index) in coordinatesMarker"
              :key="'MakerId_' + index + device_cont + coordinatesMarker.length"
              :coordinates="coordinatesMarker[index]"
              color="#00D455"
            >
              <MglPopup>
                <div class="demo-inline-spacing">
                  <b-avatar
                    variant="light-dark"
                    style="margin-right: 0.5rem; margin-top: 0rem"
                  >
                    <feather-icon
                      :icon="possible_icons[devices[index].icon].icon"
                      size="20"
                    />
                  </b-avatar>
                  <div style="margin-top: 0rem">
                    <h5 class="mb-0">
                      {{ devices[index].name }}
                    </h5>
                    <small class="text-muted">
                      {{ new Date(item[2] * 1000).toLocaleString() }}
                    </small>
                  </div>
                </div>
              </MglPopup>
            </MglMarker>

            <MglNavigationControl position="top-right" />
          </MglMap>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormRadio,
  BButton,
  BFormSpinbutton,
  BOverlay,
} from "bootstrap-vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl, MglPopup } from "v-mapbox";
import axios from "axios";
import store from "@/store/index";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import EventBus from "@/store/eventBus";
import api from "../../service/api.js";

export default {
  props: ["request"],
  components: {
    BFormSpinbutton,
    BOverlay,
    BFormCheckbox,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    MglMap,
    MglMarker,
    BButton,
    MglNavigationControl,
    MglPopup,
    MapboxDraw,
  },

  data() {
    return {
      device_overlay: false,
      device_cont: 0,
      destroy_it: false,
      refresh_data: [],
      cont: 0,
      ready: false,
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      accessToken:
        "pk.eyJ1IjoiZHRpYWdvIiwiYSI6ImNrYzB4b2JlODE4azMyc3U2dnJ0M3RzdmIifQ.JjLKI9mfpEot4J2FNEtNOQ",
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [-46.9397181, -19.5933794],
      coordinatesMarker: [],
      coordinatesToGo: [0, 0],
      zoom: 4,
      current_filter: 0,
      devices: [],
      filtered_devices: [],
    };
  },
  async created() {
    var self = this;
    self.mapbox = Mapbox;
    self.devices = await api.get_devices();
    self.filtered_devices = self.devices.filter(e => self.request.split("&id=").includes((e.id).toString()) == true)
    for (let i = 1; i <= self.filtered_devices.length; i++) {
      try{
        let response = await api.get_lasts_coords(self.request.split("&id=")[i]);
        self.coordinatesMarker[i - 1] = [
          response[0].value.split(",")[1],
          response[0].value.split(",")[0],
          response[0].valueTime,
        ];
      } catch {
        self.filtered_devices.splice(i-1, 1)
      }
    }
    
    self.ready = true;
  },
  destroyed() {
    this.destroy_it = true;
  },
  methods: {
    async request_data() {
      let self = this;

      if (self.destroy_it) {
        clearInterval(self.refresh_data);
      }

      for (let i = 1; i <= self.filtered_devices.length; i++) {
        let response = await api.get_lasts_coords(
          self.request.split("&id=")[i]
        );
        if (response != "error") {
          self.coordinatesMarker[i - 1] = [
            response[0].value.split(",")[1],
            response[0].value.split(",")[0],
            response[0].valueTime,
          ];
        }
      }
      self.cont += 1;
    },
    async onMapLoaded(event) {
      let self = this;
      self.refresh_data = setInterval(self.request_data, 10000);

      self.map = event.map;
      self.$store.map = event.map;
      self.map.resize();
      self.asyncActions = event.component.actions;
    },

    async onChangeDevice(device) {
      let self = this;
      self.device_cont += 1;
      self.device_overlay = true;
      let response = await api.get_lasts_coords(device);
      self.coordinatesToGo = [
        response[0].value.split(",")[1],
        response[0].value.split(",")[0],
      ];
      self.device_overlay = false;
      self.newParams = await self.asyncActions.flyTo({
        center: self.coordinatesToGo,
        zoom: 17,
        speed: 1,
      });
    },
  },
};
</script>

<style></style>
