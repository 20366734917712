<!--   -->
<template>
    <b-row>
      <b-col
        lg="12"
        sm="12"
      >
        <statistic-card-horizontal
          v-if="ready"
          responsible
          :icon="possible_icons[device.icon].icon"
          :statistic="get_data.value + ' ' + variable.unit"
          :statistic-title="widget_title"
        ></statistic-card-horizontal>
      </b-col>
    </b-row>
</template>

<script>
import { BCard, BRow, BCol, BFormRadio } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import store from '@/store/index'
import axios from "axios";

export default {
    props: ['request_device', 'request_variable'],
 
    components: {
        BCard,
        BRow,
        BCol,
        BFormRadio,
        StatisticCardHorizontal,
        
    },
    data() {
    return {
      on_loop: '',
      stop_loop: false,
      loop: true,
      patch_widget: '',
      ready: false,
      widget_title: '',
      get_data: [],
      variable: [],
      device: [],
      possible_icons: [
       {
         icon: 'BoxIcon',
         text: 'Cubo',
         id: 0,
         selected: 'flat-success'
       },
       {
         icon: 'BatteryIcon',
         text: 'Bateria',
         id: 1,
         selected: 'flat-darken-2'
       },
       {
         icon: 'DropletIcon',
         text: 'Humidade',
         id: 2,
         selected: 'flat-darken-2'
       },
       {
         icon: 'ThermometerIcon',
         text: 'Temperatura',
         id: 3,
         selected: 'flat-darken-2'
       },
       {
         icon: 'NavigationIcon',
         text: 'Seta',
         id: 4,
         selected: 'flat-darken-2'
       },
       {
         icon: 'Navigation2Icon',
         text: 'Seta#2',
         id: 5,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MousePointerIcon',
         text: 'Mouse',
         id: 6,
         selected: 'flat-darken-2'
       },
       {
         icon: 'TruckIcon',
         text: 'Caminhão',
         id: 7,
         selected: 'flat-darken-2'
       },
       {
         icon: 'UserIcon',
         text: 'Usuário',
         id: 8,
         selected: 'flat-darken-2'
       },
       {
         icon: 'WatchIcon',
         text: 'Relógio',
         id: 9,
         selected: 'flat-darken-2'
       },
       {
         icon: 'EyeIcon',
         text: 'Olho',
         id: 10,
         selected: 'flat-darken-2'
       },
       {
         icon: 'MusicIcon',
         text: 'Música',
         id: 11,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HeartIcon',
         text: 'Coração',
         id: 12,
         selected: 'flat-darken-2'
       },
       {
         icon: 'HomeIcon',
         text: 'Casa',
         id: 13,
         selected: 'flat-darken-2'
       },
    
     ],

    };
  },
  async created() {
    await axios
        .get("https://tekie.cloud:3031/devices?id=" + this.request_device , {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.device = response.data.data[0];
          this.widget_title = this.device.name + " - ";
          this.ready = true;
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });

    await axios
      .get("https://tekie.cloud:3031/variables?id=" + this.request_variable , {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {
        this.variable = response.data.data[0]
        this.widget_title += this.variable.name
      })
      .catch((e) => {
        //"Erro ao pegar variables: ", e);
      });

      let request_url = "https://tekie.cloud:3031/datas?$limit=1&$skip=0&deviceId=" + this.request_device.substring(4) + "&variableId=" + this.request_variable;
      await axios
      .get(request_url, {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })
      .then((response) => {
        this.get_data = 
          {
            value: 0
          }
        
        if(response.data.data.length != 0){
          this.get_data = response.data.data[0]

        }
      })
      .catch((e) => {
        //"Erro ao pegar devices: ", e);
      });
      this.loop = true;
      this.on_loop = setInterval(this.request_data, 10000); 
  },
  destroyed(){
    this.stop_loop = true;
  },
  methods: {
    async request_data(){
      if(this.stop_loop == true){
        clearInterval(this.on_loop)
      }
      this.loop = false;
      let request_url = "https://tekie.cloud:3031/datas?$skip=0&deviceId=" + this.request_device.substring(4) + "&variableId=" + this.request_variable;
      await axios
        .get(request_url, {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.get_data = 
            {value: 0}
          if(response.data.data.length != 0){
            this.get_data = response.data.data[0]
          }
        })
        .catch((e) => {
          //"Erro ao pegar devices: ", e);
        });
      
      

    }

  },
}
</script>

<style>

</style>
