<template>
  <div style="height: 100%; width: 100%">
    <b-overlay
      style="height: 100%; width: 100%"
      opacity="0.5"
      :show="!ready"
      spinner-variant="success"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row style="height: 100%; margin: 0rem">
        <b-table          
          responsive
          :key="'table_' + cont"
          style="height: 100%"
          v-if="ready"
          :items="new_data"
        >
          <template #cell(valueTime)="data">
            {{ new Date(data.item.valueTime * 1000).toLocaleString() }}
          </template>

          <template #cell(createdAt)="data">
            {{ new Date(data.item.createdAt).toLocaleString() }}
          </template>

          <template #cell(deviceId)="data">
            <!-- {{ device.find((e) => e.id == data.item.deviceId).name }} -->abc
          </template>

          <template #cell(variableId)="data">
            <!-- {{ variable.find((e) => e.id == data.item.variableId).name }} -->def
          </template>
        </b-table>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormRadio, BTable, BOverlay } from "bootstrap-vue";
import store from "@/store/index";
import axios from "axios";
import api from "@/service/api";

export default {
  props: ["request_device", "request_variable"],

  components: {
    BOverlay,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormRadio,
  },
  data() {
    return {
      device_data_request: "",
      variable_data_request: "",
      cont: 0,
      new_data: [],
      on_loop: false,
      ready: false,
      device: [],
      variable: [],
      series: [],
      fields: [
        {
          key: "value",
          label: "Valor",
          sortable: true,
        },
        {
          key: "variableId",
          label: "Variável",
          sortable: true,
        },
        {
          key: "deviceId",
          label: "Dispositivo",
          sortable: true,
        },
        {
          key: "valueTime",
          label: "Data dispositivo",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Data servidor",
          sortable: true,
        },
      ],
      items: [
        {
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
          Occupation: "Job",
        },
      ],
    };
  },
  async created() {
    var self = this;    
    self.device = await api.find_devices(self.request_device);

    let variable_request = "?&id=" + self.request_variable.replace(/,/gi, "&id=");
    self.variable = await api.find_variables(variable_request);

    self.device_data_request =
      "?" + self.request_device.replace(/&id/gi, "&deviceId");
    self.variable_data_request =
      "&variableId=" + self.request_variable.replace(/,/gi, "&variableId=");

    var all_datas = await api.get_data(
      self.device_data_request,
      self.variable_data_request      
    );
    let variable_request_name = self.request_variable.split(',');
    variable_request_name.forEach((item, index) => {
      variable_request_name[index] = self.variable.find(e => e.id == item).name;
    });
    let all_valuetimes = [];
    let all_devices = [];
    all_datas.forEach((element) => {
      if (!all_valuetimes.includes(element.valueTime)) {
        all_valuetimes.push(element.valueTime);
        all_devices.push(element.deviceId);
      }
    });
    let temp_data = [];
    self.new_data = [];
    all_valuetimes.forEach((item, index) => {
      temp_data[index] = [];
      variable_request_name.forEach((element, pos) => {        
        temp_data[index].push({
          variable: pos
        })        
        let data_value = '';
        try {
          data_value = all_datas.find(e => e.valueTime == item && e.variableId == self.request_variable.split(',')[pos]).value;
        } catch {
          data_value = '';
        }
        let return_data = ({[variable_request_name[pos]]: data_value});
        temp_data[index][pos] = return_data;
        if(pos == (variable_request_name.length - 1)){  
          temp_data[index][variable_request_name.length] = {
            'Dispositivo': self.device.find(e => e.id == all_devices[index]).name,
            'Data Dispositivo': new Date(item * 1000).toLocaleString(),
            'Data Servidor': new Date(all_datas.find(e => e.valueTime == item && e.variableId == self.request_variable.split(',')[pos]).createdAt).toLocaleString()
          }        
          for(let i = 1; i <= (variable_request_name.length + 2); i++){ // + 2 por causa das colunas adicionais
            temp_data[index][0] = { ...temp_data[index][0], ...temp_data[index][i]};
          }
        }
      }); 
      self.new_data[index] = temp_data[index][0];
    });

    self.ready = true;
    
    this.loop = true;
    // this.on_loop = setInterval(this.request_data, 12000);
  },
  destroyed() {
    this.stop_loop = true;
  },
  methods: {
    async request_data() {
      // if (this.stop_loop == true) {
      //   clearInterval(this.on_loop);
      // }
      // this.new_data = await api.get_data(
      //   this.device_data_request,
      //   this.variable_data_request,
      //   "50"
      // );
      // this.cont += 1;
    },
  },
};
</script>

<style>
</style>
